<template>
  <div class="c-participation">
    <exerciseHeader/>

    <component :is="pageComponent" />
  </div>
</template>

<script>
  export default {
    name: "judge-exercise",
    components: {
      'exerciseHeader': require('client/components/models/exerciseHeader.vue').default,
      'exerciseTimer': require('./exerciseTimer.vue').default,
      'counter': require('./counter.vue').default,
      'edit-scores': require('./edit-scores.vue').default,
      'confirm-scores': require('./confirm-scores.vue').default,
      'exercise-edit': require('../chair/exercise-edit.vue').default,
    },
    data: function() {
      return {
        pageComponent: 'confirm-scores',
      };
    },
    computed: {
      activePass: function () {
        return this.$route.params.pass;
      },
      exercise: function () {
        return this.$store.state.exercise.exercise;
      },
      chair: function () {
        return this.$store.state.exercise.chair;
      },
      timer: function () {
        return this.$store.state.exercise.timer;
      },
      pages: function () {
        return this.$store.state.exercise.pages;
      },
      currentPage: function () {
        return this.$store.state.exercise.currentPage;
      },
      inputFinished: function () {
        return this.$store.state.exercise.inputFinished;
      }
    },
    created: function() {
      this.setCurrentComponent();
    },
    watch: {
      pages: function () {
        this.setCurrentComponent();
      },
      currentPage: function () {
        this.setCurrentComponent();
      },
      inputFinished: function () {
        this.setCurrentComponent();
      },
    },
    methods: {
      setCurrentComponent: function() {
        if (this.chair && this.inputFinished) {
          this.pageComponent = 'exercise-edit';
          return;
        } else if (this.currentPage === -1 || this.currentPage === this.pages.length) {
          this.pageComponent = 'confirm-scores';
          return;
        }

        const page = this.pages[this.currentPage];
        switch (page.type) {
          case 'group':
            this.pageComponent = 'edit-scores';
            break;
          case 'timer':
            this.pageComponent = 'exerciseTimer';
            break;
          case 'counter':
            this.pageComponent = 'counter';
            break;
        }
      },

      cancel: function () {
        this.$store.dispatch("exercise.cancel", {});
      },
    }
  };
</script>

<style scoped>
</style>
