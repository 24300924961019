<template>
  <div>
    <div class="o-row o-row-tiny">
      <h3 class="c-title c-title--event u-push-1-of-5 u-3-of-5 u-padding-left-small">
        <!-- Select your panel position -->
        {{panel.name}}
      </h3>
    </div>

    <div class="o-layout u-margin-bottom-medium" v-for="(groupPositions, group) in positions" :key="group">
      <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">{{$t('score.' + group)}}</h4>
      <div class="c-button--group o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
        <template v-for="position in groupPositions">
          <router-link class="c-button c-button--large c-button--secondary" :key="'edit-' + position.position"
                       v-if="position.member" exact
                       :to="{ name: 'judge.panel.member.confirm', params: { eventId: $route.params.eventId, panelId: $route.params.panelId, panelMemberId: position.member.id} }">

              {{position.index}}
              <span class="c-button__extra-label">{{getJudge(position.member.judgeId).firstname}} {{getJudge(position.member.judgeId).lastName}}</span>

          </router-link>
          <router-link class="c-button c-button--large c-button--primary" :key="'add-' + position.position"
                       v-else exact
                       :to="{ name: 'judge.panel.member.add', params: { eventId: $route.params.eventId, panelId: $route.params.panelId, position: position.position} }">
              {{position.index}}
              <span class="c-button__extra-label">{{$t("Free")}}</span>
          </router-link>
        </template>
      </div>

    </div>
    </div>
</template>

<script>
  import panelHandler from 'client/lib/panelHandler';

  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import forEach from 'lodash/forEach';

  export default {
    name: "panel-members",
    data: function() {
      return {
        panelType: undefined,
        positions: [],
      }
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      panel: function () {
        return find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId });
      },
      judges: function () {
        return filter(this.$store.state.members.items, item => { return item.judge });
      }
    },
    mounted: function() {
      this.panelType = find(this.$store.state.eventDiscipline.panelTypes, item => {
        return item.id === this.panel.panelTypeId;
      });
      this.calculatePositions();
    },
    watch: {
      panel: function() {
        this.calculatePositions();
      }
    },
    methods: {
      calculatePositions: function() {
        if (this.panel && this.panelType) {
          const positionList = panelHandler.calculatePositions(this.panel, this.panelType);
          let positions = {};

          forEach(positionList, position => {
            if (! positions[position.key]) {
              positions[position.key] = [];
            }
            positions[position.key].push(position);
          });

          this.positions = positions;
        }
      },
      getJudge: function (judgeId) {
        return find(this.judges, judge => {
          return judge.id === judgeId;
        });
      }
    }
  }
</script>

<style scoped>
</style>
