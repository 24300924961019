<template>
  <div class="c-button--options-pad">
    <button v-for="option of options" v-on:click.prevent="valuePressed(option.value)"
            :key="option.value" class="c-button c-button--square c-button--primary">
      {{option.label}}
    </button>
  </div>
</template>

<script>
  export default {
    name: "select-options",
    props: ["valueType"],
    computed: {
      options: function() {
        return this.valueType.options;
      }
		},
    methods: {
      valuePressed: function(value) {
        this.$emit('value', {value: value});
        this.actionPressed('next');
      },
      actionPressed: function(action) {
        this.$emit('action', {action: action});
      }
    }
  };
</script>

<style scoped>
  button {
    margin: 8px;
    font-size: 18px;
    height: 50px;
    width: 70px;
  }
</style>
