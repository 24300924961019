<template>
  <div>
    <header class="c-header o-wrapper o-wrapper--huge js-header">
      <router-link :to="{ name: 'judge.panels'}" class="c-logo__link">
        <div class="c-logo"></div>
      </router-link>
      <router-link exact
                   :to="{ name: 'judge.panels'}"
                   class="c-header__title u-push-1-of-5 u-3-of-4">
        {{getEventTitle()}}
      </router-link>
      <breadcrump/>
    </header>
    <main class="o-row">
      <template class="c-row" v-for="ed in eventDisciplines">
        <div class="c-row" :key="ed.id" v-if="hasSessions(ed.id)">
          <h3 class="c-title c-title--event u-push-1-of-5 u-3-of-5 u-padding-left-small">{{ed.name}}</h3>

          <template v-for="session in getSessions(ed.id)">
            <div class="o-layout u-margin-bottom-medium" v-for="set in getSets(session)" :key="session.id + '-' + set">
              <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">
                {{session.name}}<span v-if="isPerSet(session) && session.sets > 1"> - {{$t('set')}} {{set}}</span>
              </h4>
              <div class="c-button--group o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
                <template v-for="panel in getPanels(session, set)">
                  <router-link :key="panel.id"
                    class="c-button c-button--large c-button--primary"
                    :to="{ name: 'judge.panel.members', params: { eventId: $route.params.eventId, panelId: panel.id}}">
                    {{$t('panel')}} {{getPanelIndex(session, panel)}}
                    <span class="c-button__extra-label" v-if="panel.exerciseTypeId">{{$t('exercise.type.'+ panel.exerciseTypeId)}}</span>
                    <span class="c-button__extra-label" v-else>{{getDisciplineName(session)}}</span>
                  </router-link>
                </template>
                <router-link v-if="useCounters(ed)"
                  class="c-button c-button--large c-button--secondary"
                  :to="{ name: 'judge.counters', params: { sessionId: session.id}}">
                  {{$t('counters')}}
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </template>
    </main>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';
  import sessionLib from "@/lib/session";

  export default {
    name: "panels",
    components: {
      breadcrump: require('./common/breadcrump.vue').default,
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      eventDisciplines: function () {
        return this.$store.state.eventDisciplines.items;
      },
      disciplines: function () {
        return this.$store.state.disciplines.items;
      },
      sessions: function () {
        const tmp = filter(this.$store.state.sessions.items, function (session) {
          return session.status === 'active'
        });
        return sortBy(tmp, 'index');
      },
      panels: function () {
        return sortBy(this.$store.state.panels.items, 'index');
      },
    },
    mounted: function() {
       window.addEventListener('scroll', () => {
          if (document.querySelector('.js-header')) {
              if (window.scrollY > 50 ) {
                if (!document.querySelector('.js-header').classList.contains('is-shrinked')) {
                  document.querySelector('.js-header').classList.add('is-shrinked');
                }
              } else {
                if (document.querySelector('.js-header').classList.contains('is-shrinked')) {
                  document.querySelector('.js-header').classList.remove('is-shrinked');
                }
              }
          }
        })
    },
    methods: {
      getSets: function(session) {
        return this.isPerSet(session) ? session.sets : 1;
      },
      getDiscipline: function(session) {
        const eventDiscipline = find(this.eventDisciplines, item => {
          return item.id === session.eventDisciplineId;
        });
        return find(this.disciplines, item => {
          return item.id === eventDiscipline.disciplineId;
        });
      },
      isPerSet: function(session) {
        return sessionLib.getSessionRotationType(session.id) === 'rotation';
      },
      getPanels: function (session, set) {
        return sortBy(filter(this.panels, item => {
          return item.sessionId === session.id && (!this.isPerSet(session) || item.set === set);
        }), ['set', 'index']);
      },
      hasSessions: function(edId) {
        return this.getSessions(edId).length > 0;
      },
      getSessions: function (edId) {
        return filter(this.sessions, item => {
          return item.eventDisciplineId === edId;
        });
      },
      getPanelIndex: function(session, panel) {
        return this.isPerSet(session) ? panel.index+1 : panel.set;
      },
      getEventTitle: function() {
        return this.event.titleShort ? this.event.titleShort : this.event.title;
      },
      getDisciplineName: function(session) {
        const discipline = this.getDiscipline(session);
        return discipline.name;
      },
      useCounters: function(eventDiscipline) {
        const discipline = find(this.disciplines, item => item.id === eventDiscipline.disciplineId);
        return discipline.config.useCounters;
      }
    }
  }
</script>

<style scoped>
</style>
