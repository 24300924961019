<template>
  <exerciseEdit mode="panel" />
</template>

<script>
  export default {
    name: "exercise-panel",
    components: {
      'exerciseEdit': require('client/components/models/exercise-edit.vue').default,
    }
  };
</script>

<style scoped>
</style>
