<template>
<div class="counter c-participation">
  <div class="top">
    <div class="count">{{value}}</div>
    <button v-on:click.prevent="minus()" class="c-button c-button--secondary">
      {{$t('counter.minus')}}
    </button>
    <button v-on:click.prevent="buttonReset()"  class="c-button c-button--secondary">
      {{$t('counter.reset')}}
    </button>
    <button v-on:click.prevent="next()" class="c-button c-button--primary">
      {{$t('finish')}} >
    </button>
  </div>
  <div class="main">
    <button v-on:click.prevent="plus()" class="c-button c-button--primary" style="height: 100%">
      {{$t('counter.plus')}}
    </button>
  </div>
</div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep';

  const scoreHandler = require('lib/scoreHandler.js');

  export default {
    name: "counter",
    data: function() {
      return {
        value: 0,
        scoreValue: {},
      }
    },
    computed: {
      exercise: function () {
        return this.$store.state.exercise.exercise;
      },
      activePass: function () {
        return this.$store.state.exercise.pass;
      },
      exerciseType: function() {
        return this.$store.state.exercise.exerciseType;
      },
      currentPage: function() {
        return this.$store.state.exercise.currentPage;
      },
      pages: function() {
        return this.$store.state.exercise.pages;
      }
    },
    created: function () {
      this.init();
    },
    methods: {
      init: function() {
        const page = this.pages[this.currentPage];
        const properties = page.elements[0].values[0].properties;
        let value = scoreHandler.exerciseGetValue(this.exercise, properties);
        this.setValue(value === undefined ? 0 : value);
      },
      buttonReset: function() {
        this.$modal.show({
          title: this.$t('counter.reset'),
          message: this.$t('counter.reset.text'),
          onConfirm: () => {
            this.setValue(0);
          }
        });
      },
      minus: function() {
        this.setValue(this.value - 1);
      },
      plus: function() {
        this.setValue(this.value + 1);
      },
      setValue: function(newValue) {
        this.value = newValue;
        const opts = {
          value: this.value,
        };
        this.$store.dispatch('exercise.emitCounter', opts);
      },
      next: function () {
        const page = this.pages[this.currentPage];
        let opts = cloneDeep(page.elements[0].values[0].properties);
        opts.value = this.value;
        opts.exerciseId = this.exercise.id;

        this.$store.dispatch('exercise.setScoreValue', opts);
        this.$store.dispatch("exercise.nextPage", {});
      },
    },
  }
</script>

<style scoped>
  .counter {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .counter .top {
    display: flex;
    flex-direction: row;
    flex-basis: 25%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .count {
    font-size: 200%;
    padding: 0.5em 2em;
  }

  .counter .main {
    flex-grow: 1;
    height: 500px;
  }

  .c-button {
    width: 100%;
    font-size: 200%
  }
</style>
