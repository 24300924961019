<template>
  <router-view></router-view>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: 'chair-wrapper',
    data: function() {
      return {
        page: 'idle',
        judge: undefined
      }
    },
    computed: {
      panel: function () {
        return find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId });
      },
      exercise: function() {
        const panel = find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId });
        if (panel.exerciseId) {
          return find(this.$store.state.exercises.items, item => { return item.id === panel.exerciseId });
        }
        return undefined;
      },
      activePass: function() {
        return this.$store.state.exercise.pass;
      },
    },
    mounted: function() {
      const member = find(this.panel.members, item => { return item.id === this.$route.params.panelMemberId });
      if (member) {
        this.judge = find(this.judges, item => { return item.id === member.judgeId });
      }

      this.setState();
    },
    watch: {
      panel: function() {
        this.setState();
      },
    },
    methods: {
      setState: function() {
        if (! this.panel.active) {
          this.$navigation.goto({name: 'judge.panels'});
          return;
        }

        if (this.panel.activeRotation === undefined || this.panel.activeRotation === null) {
          this.$navigation.goto({name: 'judge.chair.rotations'});
          this.page = 'idle';
          return;
        }

        if (! this.panel.exerciseId) {
          if (this.page === 'idle-block') return;
          this.page = 'idle-block';
          this.$navigation.goto({name: 'judge.chair.block'});
          return;
        }

        if (this.activePass !== this.panel.activePass) {
          this.$store.dispatch("exercise.setPass", this.panel.activePass);
        }

        this.page = 'exercise';
        this.$navigation.goto({
          name: 'judge.chair.exercise',
          params: { exerciseId: this.panel.exerciseId, pass: this.panel.activePass }});
      }
    }
  };
</script>

<style scoped>
</style>
