<template>
  <div class="c-container__body">
    <div class="o-row o-row-tiny c-container__grown-child">
      <div class="o-layout">
        <h3
          class="c-title c-title--event u-1-of-1 u-push-1-of-5-at-small u-3-of-5-at-small u-padding-left-medium u-margin-bottom-none">
          {{ $t('panel.idleMessage') }}
        </h3>
      </div>
    </div>

    <template
      v-if="showPreviousExercise &&exercise && exercise.id === panel.previousExerciseId && exercise.status === 'finished'">
      <div class="o-row o-row-tiny">
        <div class="o-layout">
          <h3
            class="c-title c-title--event u-1-of-1 u-push-1-of-5-at-small u-3-of-5-at-small u-padding-left-medium u-margin-bottom-none">
            {{ $t('panel.previousResult') }}</h3>
        </div>
      </div>
      <div class="o-row o-row-tiny">
        <exercise
          :blockParticipation="getDummyBlockPart()"
          :exerciseTypeId="exercise.exerciseTypeId" :panelId="panel.id"
          :pass="0" :noedit="true"/>

      </div>
    </template>
  </div>
</template>

<script>
import find from 'lodash/find';

export default {
  name: "idle",
  components: {
    exercise: require('client/components/models/blockExercise.vue').default,
  },
  data: function () {
    return {
      participation: undefined,
      showPreviousExercise: false,
    };
  },
  computed: {
    exercise: function () {
      return this.$store.state.exercise.exercise;
    },
    panel: function () {
      return find(this.$store.state.panels.items, item => {
        return item.id === this.$route.params.panelId
      });
    },
  },
  created: function () {
    const eventDiscipline = find(this.$store.state.eventDisciplines.items, item => {
      return item.id === this.panel.eventDisciplineId;
    });

    this.showPreviousExercise = !eventDiscipline.judgesHideScores
  },
  methods: {
    getDummyBlockPart: function () {
      return {
        participationId: this.exercise.participationId,
      }
    },
  },
};
</script>
