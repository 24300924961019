<template>
  <div>
    <panelmember :position="$route.params.position" @submit="update($event)" @cancel="cancel($event)"/>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import includes from 'lodash/includes';

  export default {
    name: 'panelMember-add',
    components: {
      'panelmember': require('./member-config.vue').default
    },
    data: function () {
      return {
        panelType: undefined
      }
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      panel: function () {
        return find(this.$store.state.panels.items, item => {
          return item.id === this.$route.params.panelId
        });
      }
    },
    mounted: function () {
      this.panelType = find(this.$store.state.eventDiscipline.panelTypes, item => {
        return item.id === this.panel.panelTypeId
      });
    },
    methods: {
      update: function (panelMember) {
        const opts = {
          panelId: panelMember.panelId,
          data: panelMember,
        };
        this.$store.dispatch('panel.member.add', opts).then(result => {
          const chairPosition = this.panelType.chairPosition;
          if (includes(result.data.positions, chairPosition)) {
            this.$navigation.goto({name: 'judge.chair.rotations', params: {panelMemberId: result.data.id}})
          } else {
            this.$navigation.goto({name: 'judge.judge.idle', params: {panelMemberId: result.data.id}})
          }
        })

      },
      cancel: function () {
        this.$navigation.goto({name: 'judge.panel.members'})
      }
    }
  }
</script>

<style scoped>

</style>
