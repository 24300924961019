<template>
  <li class="o-list-bare__item">
    <div class="c-display-counters__row c-display-counters__top">
      <div class="c-display-counters__field">
        {{$t('field')}} {{panel.set}}
        <div class="c-display-counters__name">
          {{name}}
        </div>
        <div class="c-display-counters__club">
          {{club}}
        </div>
      </div>
      <div class="c-display-counters__count">
        {{count}}
      </div>
    </div>
  </li>
</template>

<script>
  import find from 'lodash/find';

  import participantLib from 'client/lib/participant';

  import socket from 'client/socket';

  export default {
    name: "judge.counter",
    props: ['panel'],
    data: function() {
      return {
        name: undefined,
        club: undefined,
        count: 0,
        exercise: null,
      };
    },
    computed: {},
    created: function() {
      socket.emit('join','counters');
      socket.on('counter.update', data => {
        if (this.exercise && this.exercise.id === data.exerciseId) {
          this.count = data.value;
        }
      });
    },
    watch: {
      panel: {
        handler: function() {
          this.init();
        },
        immediate: true,
      }
    },
    methods: {
      init: function() {
        if (this.panel.exerciseId) {
          this.exercise = find(this.$store.state.exercises.items, item => {
            return item.id === this.panel.exerciseId;
          });
        } else {
          this.exercise = null;
        }

        if (this.panel.exerciseId && this.exercise) {
          const participation = participantLib.getParticipation(this.exercise.participationId);
          const participant = participantLib.getParticipant(participation);
          this.name = participantLib.getName(participation);
          const club = participantLib.getClub(participant);
          this.club = club.name;
        } else {
          this.club = undefined;
          this.name = undefined;
          this.count = 0;
        }
      },
    },
  };
</script>

<style scoped>
</style>
