<template>
  <div class="c-display__wrapper js-content">
    <header class="c-display-header">
      <div class="c-display-header__info">
        <h1 class="c-display-header__title c-display-header__title--small">
          {{eventDisciplineName(eventDiscipline)}}
        </h1>
      </div>
      <div class="c-display-header__logo">
        <img :src="eventDiscipline.logoUrl" alt="">
      </div>
    </header>
    <main class="c-display-counters">
      <ul class="c-display-counters__list o-list-bare" :class="{'c-display_counters__list--small': panels.length < 3}">
        <counter v-for="panel of panels" :key="panel.id" :panel="panel" class="c-display-counters__item" />
      </ul>
    </main>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';

  export default {
    name: 'judge.counters',
    components: {
      counter: require('./counter.vue').default,
    },
    computed: {
      session: function() {
        return find(this.$store.state.sessions.items, item => item.id === this.$route.params.sessionId);
      },
      eventDiscipline: function() {
        return find(this.$store.state.eventDisciplines.items, item => {
          return item.id === this.session.eventDisciplineId;
        });
      },
      panels: function() {
        return sortBy(filter(this.$store.state.panels.items, item => item.sessionId === this.$route.params.sessionId), ['set', 'index']);
      }
    },
    methods: {
      eventDisciplineName: function(eventDiscipline) {
        return eventDiscipline.shortName ? eventDiscipline.shortName : eventDiscipline.name;
      },
    },
  }
</script>

<style scoped>
</style>
