<template>
  <form id="panelMember" action="#" class="c-panel-form">
    <router-link
            :to="{ name: 'judge.panel.members', params: { eventId: $route.params.eventId, panelId: $route.params.panelId}}"
            class="c-panel-form__close">
        ×
    </router-link>
    <h2 class="c-title c-panel-form__title">{{panel.name}} - {{$t('edit')}}</h2>
    <div class="o-layout c-panel-form__item">
      <h4 class="c-panel__label o-layout__item u-1-of-1 u-1-of-5-at-small">
        {{$t('name')}}
      </h4>
      <v-select
          v-model="selectedJudge"
          :options="formatJudges()"
          class="o-layout__item u-1-of-1 u-4-of-5-at-small" />
    </div>

    <div class="o-layout c-panel-form__item">
      <h4 class="c-panel__label o-layout__item u-1-of-1 u-1-of-5-at-small">
        {{$t('club')}}
      </h4>
      <v-select
        v-model="selectedClub"
        :options="formatClubs()"
        class="o-layout__item u-1-of-1 u-4-of-5-at-small" />
    </div>
    <div class="o-layout c-panel-form__item">
      <h4 class="c-panel__label o-layout__item u-1-of-1 u-1-of-5-at-small">
        {{$t('panel.member.positions')}}
      </h4>
      <div class="o-layout__item u-1-of-1 u-4-of-5-at-small">
        <div class="o-layout">
          <div v-for="position in selectedPositions" :key="position"
               class="o-layout__item u-1-of-1 u-1-of-2-at-small">
            <div class="c-panel__field">
              <div class="positions__row_title">
                <span>{{getPosition(position).name}}</span>
              </div>
              <div class="positions__row_delete" :id="'remove-position-button-' + position">
                <button v-on:click.prevent="removePosition(position)"
                        class="c-button c-button--ghost">
                  ×
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-small u-4-of-5-at-small u-push-1-of-5-at-medium u-4-of-6-at-medium">
        <button id="add-position-button"
                v-on:click.prevent="selectPosition = true"
                class="c-button c-button--secondary c-button--small">

          <span class="c-button__symbol">+</span> {{$t('panel.member.addPosition')}}

        </button>
      </div>
    </div>

    <modal :shown="selectPosition" @closed="selectPosition = false">
      <div v-if="addPosition" class="c-panel__dropdown">
        <h4 class="c-panel__label u-margin-bottom-small">
            {{$t('panel.member.addPosition')}}
        </h4>
        <button v-for="position in availablePositions" :key="position.position"
                v-on:click.prevent="addPosition(position.position)"
                class="c-button c-button--small c-button--primary">
          {{position.name}}
        </button>
        <button v-on:click.prevent="selectPosition = false"
                class="c-button c-button--small c-button--secondary">
          {{$t('panel.closeModal.button')}}
        </button>
      </div>
    </modal>

    <div class="c-panel-form__footer">
      <div class="c-button__group">
        <button v-if="panelMemberId" class="c-button c-button--small c-button--ghost"
                v-on:click.prevent="clear()">
          {{$t('panel.member.clear')}}
        </button>
      </div>

      <div class="c-button__group">
        <button class="c-button c-button--small c-button--secondary"
                v-on:click.prevent="cancel()">
          {{$t('cancel')}}
        </button>
        <button class="c-button c-button--small c-button--primary"
                :disabled="!selectedJudge || !selectedPositions.length"
                v-on:click.prevent="submit()">
          {{$t('continue')}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
  import panelHandler from 'client/lib/panelHandler';

  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import includes from 'lodash/includes';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "panel-member",
    components: {
      'v-select': require('vue-select').default,
      'modal': require('client/components/modal.vue').default,
    },
    props: ['position', 'panelMemberId'],
    data: function () {
      return {
        panelType: undefined,
        panelMember: undefined,
        positions: [],
        selectedPositions: [],
        selectedJudge: undefined,
        selectedClub: undefined,
        newPosition: undefined,
        availablePositions: [],
        selectPosition: false
      };
    },
    computed: {
      judges: function () {
        return sortBy(filter(this.$store.state.members.items, item => { return item.judge }), ['lastName', 'firstname']);
      },
      panel: function () {
        return filter(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId })[0];
      },
      clubs: function() {
        return sortBy(this.$store.state.clubs.items, 'name');
      },
    },
    mounted: function () {
      this.panelType = filter(this.$store.state.eventDiscipline.panelTypes, item => { return item.id === this.panel.panelTypeId })[0];
      this.calculatePositions();

      if (this.panelMemberId) {
        this.panelMember = filter(this.panel.members, (item) => {
          return item.id === this.panelMemberId
        })[0];
      }

      if (this.panelMember) {
        const member = find(this.judges, judge => {
          return judge.id === this.panelMember.judgeId;
        });
        if (member) {
          this.selectedJudge = {label: member.lastName + ' ' + member.firstname, value: member.id}
        }

        const club = find(this.clubs, item => {
          return item.id === this.panelMember.clubId;
        });
        if (club) {
          this.selectedClub = {label: club.name, value: club.id}
        }

        this.selectedPositions = this.panelMember.positions.slice(0)
      }
      else if (this.position !== undefined) {
        this.selectedPositions = [this.position]
      }

      this.setAvailablePositions();
    },
    watch: {
      panel: function() {
        this.calculatePositions();
      }
    },
    methods: {
      calculatePositions: function() {
        if (this.panel && this.panelType) {
          this.positions = panelHandler.calculatePositions(this.panel, this.panelType);
        }
      },
      setAvailablePositions: function() {
        this.availablePositions = filter(this.positions, (item) => {
          return (item.member === undefined) && (! includes(this.selectedPositions, item.position));
        });
      },
      formatJudges: function() {
        return map(this.judges, item => {
          return {label: item.lastName + ' ' + item.firstname, value: item.id}
        });
      },
      formatClubs: function() {
        return map(this.clubs, item => {
          return {label: item.name, value: item.id}
        });
      },
      getPosition: function (position) {
        return filter(this.positions, function (item) {
          return item.position === position
        })[0];
      },
      removePosition: function (position) {
        this.selectedPositions = filter(this.selectedPositions, (item) => {
          return item !== position
        });
        this.setAvailablePositions();
      },
      addPosition: function (position) {
        this.selectedPositions.push(position);
        this.selectedPositions.sort(function (a, b) {
          return a - b;
        });
        this.setAvailablePositions();
        this.selectPosition = false;
      },

      submit: function () {
        if (!this.selectedJudge) {
          alert("No judge name selected");
          return;
        }
        if (this.selectedPositions.length === 0) {
          alert("No position selected");
          return;
        }
        const data = {
          judgeId: this.selectedJudge.value,
          clubId: this.selectedClub.value,
          positions: this.selectedPositions,
          panelId: this.$route.params.panelId
        };

        if (this.panelMember) {
          data.id = this.panelMember.id;
        }
        this.$emit('submit', data);
      },

      cancel: function () {
        this.$emit('cancel');
      },

      clear: function() {
        this.$emit('clear', { panelId: this.$route.params.panelId, panelMemberId: this.panelMemberId });
      }
    }
  };
</script>
