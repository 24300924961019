<template>
  <div class="c-container c-container--full-vh">
    <header class="c-header o-wrapper o-wrapper--huge js-header" v-if="! $route.meta.hideHeader">
      <router-link :to="{ name: 'judge.panels'}" class="c-logo__link">
        <div class="c-logo"></div>
      </router-link>
      <router-link exact :to="{ name: 'judge.panels'}" class="c-header__title u-push-1-of-5 u-3-of-5">
        {{event.title}}
      </router-link>
      <breadcrump/>
    </header>

    <!--<home class="o-row">-->
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
  import filter from 'lodash/filter';

  export default {
    name:"panel-wrapper",
    components: {
      breadcrump: require('../common/breadcrump.vue').default,
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      panel: function () {
        return filter(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId })[0];
      }
    },
    mounted: function() {
        window.addEventListener('scroll', () => {
          if (document.querySelector('.js-header')) {
              if (window.scrollY > 50 ) {
                if (!document.querySelector('.js-header').classList.contains('is-shrinked')) {
                  document.querySelector('.js-header').classList.add('is-shrinked');
                }
              } else {
                if (document.querySelector('.js-header').classList.contains('is-shrinked')) {
                  document.querySelector('.js-header').classList.remove('is-shrinked');
                }
              }
          }
        })
    },
    methods: {}
  }
</script>

<style scoped>

  .ip {
    float: right;
  }
</style>
